import './App.css';
import Volumizer from "./volumizer";

function App() {
  return (
    <div className="App">
      <Volumizer/>
    </div>
  );
}

export default App;
